import React, { type ReactElement } from "react"
import githublogo from '../assets/github.png'
import linkedin from '../assets/linkedin.png'
import medium from '../assets/medium.png'
import KuberNautical from '../assets/KuberNautical.png'
export default function Navbar(): ReactElement {
  return (
    <div className="NavBar">
      <div className="NavBarButtonContainer">
      <img className="NavBarButton" src={KuberNautical} alt="KuberNautical" /> 
      <div className="NavBarButton">KuberNautical</div>
      </div>
      <div className="NavBarButtonContainer">
        <img 
        className="NavBarButton"
        src={githublogo} 
        alt="GitHub Logo" 
        onClick={()=>window.open('https://github.com/oslabs-beta/Kubernautical','_blank')}
        />
        <img 
        className="NavBarButton"
        src={linkedin} 
        alt="LinkedIn Logo" 
        onClick={()=>window.open('https://www.linkedin.com/company/kubernautical','_blank')}
        />
        <img 
        className="NavBarButton"
        src={medium} 
        alt="GitHub Logo" 
        onClick={()=>window.open('https://medium.com/@stephenacosta756/kubernautical-a-comprehensive-kubernetes-developer-tool-3bf80c34e51a','_blank')}
        />
      <a className = "NavBarLink" href="#sectionFeatures">Features</a> 
      <a className = "NavBarLink" href="#sectionFAQ">FAQ</a>
      <a className = "NavBarLink" href="#sectionTeam">Team</a> 
      </div>
    </div>
  )
}
