import React, { type ReactElement } from "react"
import linkedinlogo from '../assets/linkedin.png'
import githublogo from '../assets/github.png'
type ProfileBoxProps = {
  name?: any
  linkedIn?: any
  gitHub?: any
  imgsrc?: any
}
export default function ProfileBox({ name, linkedIn, gitHub, imgsrc }: ProfileBoxProps): ReactElement {

  return (
    <div className="ProfileBox">
      <img
        src={imgsrc}
        alt="img"
        className="ProfileImage"
      />
      <p>{name}</p>
    <div className="ProfileButtonContainer">
    <button
        className="ProfileBoxButtons"
        onClick={() => window.open(linkedIn, '_blank')}>
        <img className=''src={linkedinlogo} alt='LinkedIn Logo' height="20px" />
        LinkedIn
      </button>
      <button
        className="ProfileBoxButtons"
        onClick={() => window.open(gitHub, '_blank')}>
        <img className='' src={githublogo} alt='GitHub Logo' height="20px" />
        Github
      </button>
    </div>
    </div>
  )
}