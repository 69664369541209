import React, { type ReactElement } from "react"

export default function Frequentlyaq(): ReactElement {
  return (
    <div className="FAQ">
      <div className="FAQColumn">
       <div className = "FaqQuestion">
        <div className="barQ"></div>
        Q: What Kubernetes providers does this application support?</div>
       <div className="FaqAnswer">
       <div className="barA"></div>
        A: Currently KuberNautical supports clusters created with Google(GKE), Microsoft(AKS), Amazon(EKS) and Minikube.</div>
       <div className = "FaqQuestion">
       <div className="barQ"></div>
        Q: Why are these metrics being displayed meaningful?</div>
       <div className="FaqAnswer">
       <div className="barA"></div>
        A: CPU and memory are essential to Kubernetes ability to properly facilitate allocation of resources to various containers within nodes.</div>
      </div>
      <div className="FAQColumn">
       <div className = "FaqQuestion">
       <div className="barQ"></div>
        Q: Could a load test be applied to an application deployed on my cluster?</div>
       <div className="FaqAnswer">
       <div className="barA"></div>
        A: KuberNautical gives users the option to test the stability of a deployed application in their Kubernetes cluster.</div>
       <div className = "FaqQuestion">
       <div className="barQ"></div>
        Q: How can I make changes to my cluster configuration?</div>
       <div className="FaqAnswer">
       <div className="barA"></div>
        A: Users have the power to make dynamic changes to a desired cluster through the robust 'Edit Cluser' option. This includes but is not limited to creating a namespace, scaling a deployment and even deploying a application with a Docker image from Docker Hub.</div>
      </div>
    </div>
  )
}
