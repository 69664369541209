import React, { type ReactElement, SyntheticEvent } from "react"

const clusterGif = 'https://github.com/NotHogue/GifStorage/blob/2c3d50eebdc0634be4815c5189e3a082d4beab53/gifs/ClusterView.gif?raw=true'
const clusterFirst = 'https://github.com/NotHogue/GifStorage/blob/fbf54ef3eaa1928acf69aab11e5af468ee745a55/firsframe/ClusterFirstFrame.jpg?raw=true';
const metricsGif = 'https://github.com/NotHogue/GifStorage/blob/2c3d50eebdc0634be4815c5189e3a082d4beab53/gifs/Metrics.gif?raw=true'
const metricsFirst = 'https://github.com/NotHogue/GifStorage/blob/fbf54ef3eaa1928acf69aab11e5af468ee745a55/firsframe/MetricsFirstFrame.jpg?raw=true';
const nsCreateGif = 'https://github.com/NotHogue/GifStorage/blob/2c3d50eebdc0634be4815c5189e3a082d4beab53/gifs/NsCreate.gif?raw=true'
const nsCreateFirst = 'https://github.com/NotHogue/GifStorage/blob/fbf54ef3eaa1928acf69aab11e5af468ee745a55/firsframe/NsCreateFirstFrame.jpg?raw=true';
const loadTestGif = 'https://github.com/NotHogue/GifStorage/blob/6c5fc3338eb80e9ea52707e442a7847343962b5b/gifs/LoadTest.gif?raw=true'
const loadTestFirst = 'https://github.com/NotHogue/GifStorage/blob/fbf54ef3eaa1928acf69aab11e5af468ee745a55/firsframe/LoadTestFirstFrame.jpg?raw=true';
export default function Features(): ReactElement {

  return (
    <div className="featuresContainer">

      <div className="feature">
      <img 
      className='FeaturesGif' 
      alt="clusterView" 
      src={clusterFirst}
      onMouseEnter={(e) => e.currentTarget.src = clusterGif}
      onMouseLeave={(e) => e.currentTarget.src = clusterFirst}
        />
      <div className="featureText">
      Robust 2D Cluster Configuration
        <div className="featurDescription">
        Users can view a complete layout of their cluster, which is fully interactive. 
        This view can be filtered by namespace, and the cluster context can be changed for 
        users with multiple clusters.
        </div>
        </div>
      </div>

      <div className="feature">
      <div className="featureText">
      Instant Health Insights 
        <div className="featurDescription">
        Cluster health can be monitored in regard to several key metrics. 
        CPU usage, Memory usage, and network transmitted vs received. 
        These metrics can all be filtered by namespace as well as time window.
         The total CPU and Memory requested can also be viewed as a percentage via static gauge graphs.
        </div>
        </div>
      <img 
      className='FeaturesGif'  
      alt="metricsGif" 
      src={metricsFirst}
      onMouseEnter={(e) => e.currentTarget.src = metricsGif}
      onMouseLeave={(e) => e.currentTarget.src = metricsFirst}
      />
      </div>

      <div className="feature" >
      <img 
      className='FeaturesGif'  
      alt="nsCreateGif" 
      src={nsCreateFirst}
      onMouseEnter={(e) => e.currentTarget.src = nsCreateGif}
      onMouseLeave={(e) => e.currentTarget.src = nsCreateFirst}
      />
        <div className="featureText">
        Effortless Resource Management
        <div className="featurDescription" >
        A variety of operations can be performed to make live changes to a cluster.
         This demonstration shows a namespace being created within the current cluster context.
        </div>
        </div>
      </div>

      <div className="feature" >
      <div className="featureText">
        Two Dimensional Cluster View
        <div className="featurDescription">
          This demonstration shows a comprehensive view of the cluster configuration, 
          which can be filtered by namespace. This cluster context of this view can also be changed
          to accomadate users with multiple clusters. 
        </div>
        </div>
      <img 
      className='FeaturesGif'  
      alt="metricsGif" 
      src={loadTestFirst}
      onMouseEnter={(e) => e.currentTarget.src = loadTestGif}
      onMouseLeave={(e) => e.currentTarget.src = loadTestFirst}
      />
      </div>

    </div>
  )

}